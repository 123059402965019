Lyte.Mixin.register("crux-user-utils", {//No I18n
	excludeIds: function(type,records, currentPos, recordsCount) {
		var primaryKey=this.getData("cxPropPrimaryKey"); //NO I18n
	var tempArr = [];
	var cxPropExclude = this.getData("cxPropExclude"); //NO I18n
	var length = records.length;
  for(var i = currentPos; i < length; i++) {
   if(tempArr.length ==  recordsCount) {
    break;
   }
   else{
    // if(cxPropExclude.indexOf(records[i].id) == -1){
    if(cxPropExclude.indexOf(records[i][primaryKey]) == -1){
			/*++++++
			this.setData("preventCall",true);//NO I18n
			if(type=="userLookup"){
				var selectedUserslength=1;
				// --var selectedUsers=this.getData('totalAddedItems'); //NO I18n
				var selectedUsers=this.getData('totalAddedObjects'); //NO I18n
			  if(this.getData('cxPropType')=="multiple"){ //NO I18n
			   // selectedUserslength=this.getData('totalAddedItems').length; //NO I18n
				 selectedUserslength=this.getData('totalAddedObjects').length; //NO I18n
			  }
     if(this.getData('selectedList')==false && this.getData('cxPropType')!="view"){ //NO I18n
			Lyte.Component.set(records[i],'lookup_selected_val',false); //No I18N
      // records[i].lookup_selected_val=false;
      for(var k=0;k<selectedUserslength;k++){
        // if(this.getData('cxPropType')=="multiple"){ //NO I18n
					// if(selectedUsers[k].id!=undefined){
					if(selectedUsers[k][primaryKey]!=undefined){
						// if(selectedUsers[k].id==records[i].id){
						if(selectedUsers[k][primaryKey]==records[i][primaryKey]){
							Lyte.Component.set(records[i],'lookup_selected_val',true); //No I18N
	           // records[i].lookup_selected_val=true;
	          }
					}
					else if(selectedUsers[k]!=undefined){
						// if(selectedUsers[k]==records[i].id){
						if(selectedUsers[k]==records[i][primaryKey]){
							Lyte.Component.set(records[i],'lookup_selected_val',true); //No I18N
	           // records[i].lookup_selected_val=true;
	          }
					}
        // }
        else{
         // if(selectedUsers==records[i].id){
         if(selectedUsers==records[i][primaryKey]){
					 // this.setData('displaySingleUser',records[i]); //NO I18n
					 Lyte.Component.set(records[i],'lookup_selected_val',true); //No I18N
          // records[i].lookup_selected_val=true;
         }
        }
       }
      }
      else{
				Lyte.Component.set(records[i],'lookup_selected_val',true); //No I18N
       // records[i].lookup_selected_val=true;
      }
      tempArr.push(records[i]);
		  }
			else{
				tempArr.push(records[i]);
			}
			this.setData("preventCall",false);//NO I18n
			++++++*/
			tempArr.push(records[i]);
		}else{
		  	Lyte.arrayUtils(this.getData("currentlyExcludedArray") , 'push', records[i]); //NO I18n
		 }
    }
   }
   this.setData('currentPos', i); //NO I18n

	 if(this.newConstructedBatch){
		 this.newConstructedBatch(tempArr);
	 }
   return tempArr;

  },
	constructArray : function(type,stopRequest){
	var networkObject=this.getData("cxPropNetworkData"); //NO I18n
  var records;
	records = this.getData('localData'); //NO I18n
	if(this.getData("cxPropCustomPagination") == undefined || this.getData("cxPropCustomPagination") == null || this.getData("cxPropCustomPagination") == false){
		if((type!='userLookup')||(type=='userLookup' && this.getData("selectedList")==false)){
			if(this.getData("pageNo")==1 || this.getData("pageNo")==0){this.setData('noMoreRecords', true);}
		}
		else if((type=='userLookup' && this.getData("selectedList")==true)){
			if(this.getData("pageNo")==1){this.setData('noMoreRecords', true);}
		}
	}
	var recordsCount = this.getData('cxPropRecords'); //NO I18n
	var currentPos = this.getData('currentPos'); //NO I18n
	if(type=="userLookup" || type=="lookupComponent"){
	 if(this.getData('selectedList')==false||this.getData('selectedList')==undefined){
	  var systemdata = this.getData('systemData'); //NO I18n
	 }
	 else{
	  var addeddata=this.getData('addedItems');//NO I18n
	 }
  }
	else{
	 var systemdata = this.getData('systemData'); //NO I18n
	}
	if(currentPos < records.length){
	 recordsCount = (records.length - currentPos) > recordsCount ? recordsCount : (records.length - currentPos);
   if(type=="userLookup" || type=="lookupComponent"){
	  if(this.getData('selectedList')==false||this.getData('selectedList')==undefined){
	   if(currentPos === 0) {
	    this.setData("systemData", this.excludeIds.call(this, type, records, currentPos, recordsCount)); //NO I18n
	   }
	   else {
	    Lyte.arrayUtils(systemdata, 'push', this.excludeIds.call(this, type,  records, currentPos, recordsCount)); //NO I18n
	   }
	  }
	  else{
	   if(currentPos === 0) {
	    this.setData("addedItems", this.excludeIds.call(this, type, records, currentPos, recordsCount)); //NO I18n
	   }
	    else {
	     Lyte.arrayUtils(addeddata, 'push', this.excludeIds.call(this, type,  records, currentPos, recordsCount)); //NO I18n
	    }
	   }
		 this.setData("multiScroll",false);//NO I18n
	  }
		else{
		 if(currentPos === 0) {
 	    this.setData("systemData", this.excludeIds.call(this, type, records, currentPos, recordsCount)); //NO I18n
 	   }
 	   else {
 	    Lyte.arrayUtils(systemdata, 'push', this.excludeIds.call(this, type , records, currentPos, recordsCount)); //NO I18n
 	   }
		 this.setData("multiScroll",false);//NO I18n
		 this.setData("scrollLoading",false);//NO I18n
		}
		if(this.updatedList){
			this.updatedList();
		}
		// if(type=="userLookup" && currentPos!=0){
		// if(this.bodydrop.querySelector('input[name=allSelectCheckbox]')!=null&&this.bodydrop.querySelector('input[name=allSelectCheckbox]').checked==true&&this.getData('selectedList')==false){
		// 	// var checkBoxes=this.bodydrop.querySelectorAll('input[name=checkbox]'); //NO I18n
		// 	var checkBoxes=this.getData("systemData"); //NO I18n
		// 	var checkBoxesLength=checkBoxes.length;
		// 	this.setData("preventAllCallBacks",true); //NO I18n
		// 	for(var i=checkBoxesLength-1;i>checkBoxesLength-1-recordsCount;i--){
		// 		var preventCheckboxChecked=true;
   //      if(this.getMethods('beforeCheckboxChecked')){ //NO I18n
   //       var preventCheckboxChecked=this.executeMethod('beforeCheckboxChecked',this.getData('systemData')[i]); //NO I18n
   //      }
   //      if(preventCheckboxChecked || preventCheckboxChecked==undefined || preventCheckboxChecked==null){
   //        this.setData("preventCallBacks",true);//NO I18n
		// 		// checkBoxes[i].parentElement.parentElement.ltProp('checked',true);
		// 		 Lyte.Component.set(this.getData('systemData')[i],'lookup_selected_val',true); //No I18N
		// 	}
		// 	}
		// 	this.setData("preventCallBacks",false);//NO I18n
	 //    this.setData("preventAllCallBacks",false); //NO I18n
		// }
		// if(this.bodydrop.querySelector('input[name=selectedAllSelectCheckbox]')!=null&&this.bodydrop.querySelector('input[name=selectedAllSelectCheckbox]').checked==false&&this.getData('selectedList')){
		// 	// var checkBoxes=this.bodydrop.querySelectorAll('input[name=checkbox]'); //NO I18n
		// 	var checkBoxes=this.getData("systemData"); //NO I18n
		// 	var checkBoxesLength=checkBoxes.length;
		// 	this.setData("preventAllCallBacks",true); //NO I18n
		// 	for(var i=checkBoxesLength-1;i>checkBoxesLength-1-recordsCount;i--){
		// 		var preventCheckboxUnSelection=true;
	 //      if(this.getMethods('beforeCheckboxUnChecked')){ //NO I18n
	 //       var preventCheckboxUnSelection=this.executeMethod('beforeCheckboxUnChecked',this.getData('systemData')[i]); //NO I18n
	 //      }
	 //      if(preventCheckboxUnSelection || preventCheckboxUnSelection==undefined || preventCheckboxUnSelection==null){
	 //        this.setData("preventCallBacks",true);//NO I18n
		// 		// checkBoxes[i].parentElement.parentElement.ltProp('checked',false);
		// 		Lyte.Component.set(this.getData('systemData')[i],'lookup_selected_val',false); //No I18N
		// 	}
		// 	}
		// 	this.setData("preventCallBacks",false);//NO I18n
	 //    this.setData("preventAllCallBacks",false); //NO I18n
		// }
	 // }
	 }
	 else if(!stopRequest) {
	  if(this.getData('noMoreRecords')) { //NO I18n
			//EXTRA++++++++++
			if(this.getData("cxPropCustomRequest")){
				if(this.customReqFunc){
				 var parameters=this.queryParams();
				 var retVal=this.customReqFunc(this.getData("cxPropNetworkData"),parameters,this.getData("isSearch"),this.getData("cxPropInputValue")) //NO I18n
				 if(retVal){
 					if(retVal.then){
 						Promise.resolve( retVal ).then(function(arg){
 							if(!this.getData("isSearch")){
 								this.setReqData(arg,type);
 							}else{
 								if(this.getData("cxPropInputValue")){
 									if(this.getData("cxPropInputValue").length!=0){
 										this.setReqData(arg,type);
 									}
 								}else{
 									this.setReqData(arg,type);
 								}
 							}
 						}.bind(this));
 					}
 				}
        }
			}else{
			//EXTRA++++++++++
			if(!this.getData("isSearch")){
				return store.findAll(networkObject.cxPropModuleName,this.queryParams(), networkObject.cxPropCacheQuery, networkObject.cxPropDataCache, networkObject.cxPropCustomData).then(function(arg){ //NO I18n
	   // return store.findAll(this.getData("cxPropModuleName"),this.queryParams(), this.getData("cxPropCacheQuery"), this.getData('cxPropDataCache')).then(function(arg){ //NO I18n
		 this.setReqData(arg,type);
		}.bind(this),function(err){
			if(this.errorOnRequest){
				if(type!="userLookup"){
					this.setData("searchLoading",false);//NO I18n
					this.setData("scrollLoading",false);//NO I18n
					this.setData("systemData",[]);//NO I18n
				}
				this.errorOnRequest(err);
			}
			var s = console.error;
		 s(err);
			this.setData("multiScroll",false);//NO I18n
		}.bind(this));
	}
	else{
	return store.triggerAction(networkObject.cxPropModuleName,"search",this.queryParams()).then(function(arg){
		// return store.triggerAction(this.getData("cxPropModuleName"),"search",this.queryParams()).then(function(arg){
			// this.setData("SearchNetworkCount",this.getData("SearchNetworkCount")-1); //NO I18n
			// if(this.getData('pendingSearchVariable').isPending && (this.getData("SearchNetworkCount")<5)){
			// 	this.setData("pendingSearchVariable",this.getData('pendingSearchVariable').isPending=false);
			// 	var typ=this.getData("pendingSearchVariable").type;
			// 	var ev=this.getData("pendingSearchVariable").evt;
			// 	this.setData("SearchNetworkCount",this.getData("SearchNetworkCount")+1); //NO I18n
			// 	if(type=="userLookup"){
			// 	 this.filterObserver.call(this, 'search', ev); //NO I18n
			// 	}
			// 	else{
			// 		this.constructArray.call(this,typ,false); //NO I18n
			// 	}
			// }
	  if(this.getData("cxPropInputValue") === "" || this.getData("cxPropInputValue") ){
				if(this.getData("cxPropInputValue").length!=0){
					this.setReqData(arg,type);
				}
			}else{
				this.setReqData(arg,type);
			}
	
		}.bind(this),function(err){
			if(this.errorOnSearch){
				if(type!="userLookup"){
					this.setData("searchLoading",false);//NO I18n
					this.setData("scrollLoading",false);//NO I18n
					this.setData("systemData",[]);//NO I18n
				}
				this.errorOnSearch(err);
			}
			var s = console.error;
		 s(err);
			this.setData("multiScroll",false);//NO I18n
		}.bind(this));
	}
	//EXTRA++++++++++
	}
	//EXTRA++++++++++
	}else{
	this.setData("multiScroll",false);//NO I18n
	}
	}else{
	 this.setData("multiScroll",false);//NO I18n
	}
	 return true;
	},
	setReqData : function(arg,type){
		var orignialResponse = arg;
		var allowThrough=true;
		var networkObject=this.getData("cxPropNetworkData"); //NO I18n
		if(this.getData("isSearch")){
			var objectKey=store.serializer[networkObject.cxPropModuleName].varPayloadKey;
		}
		var systemdata = this.getData('systemData'); //NO I18n
		this.setData("multiScroll",false);//NO I18n
		if(this.getData("isSearch")){
			if(arg && arg.constructor == Object){
				arg = arg[objectKey];
			 // arg = arg[this.getData("cxPropModuleName")];
			}
			else if(Array.isArray(arg) && arg.length==0){
		 allowThrough=false;
			}
		}else{
			if(arg && arg[networkObject.cxPropModuleName]!=undefined){
				arg=arg[networkObject.cxPropModuleName];
			}
			else if(arg && arg[store.serializer[networkObject.cxPropModuleName].varPayloadKey]!=undefined){
				arg=arg[store.serializer[networkObject.cxPropModuleName].varPayloadKey];
			}
		}
		if(arg==undefined){
			allowThrough=false;
		}
		else if(arg &&!Array.isArray(arg) && arg[networkObject.cxPropModuleName]==undefined){
	 allowThrough=false;
		}
		else if(Array.isArray(arg) && arg.length==0){
	 allowThrough=false;
		}
		else if(arg[0] && arg[0].$){
			if(arg[0].$.isError){
				allowThrough=false;
			}
		}
		if(arg && allowThrough){
			if(this.getData("cxPropCustomPagination") == undefined || this.getData("cxPropCustomPagination") == null || this.getData("cxPropCustomPagination") == false){
				this.setData('pageNo', this.getData('pageNo') + 1) //NO I18n
			}
			this.setData('noUsersAvilabel',false); //NO I18n
			if(this.getData("cxPropCustomPagination") == undefined || this.getData("cxPropCustomPagination") == null || this.getData("cxPropCustomPagination") == false){
			if(type=='userLookup'){
				if(this.getData("selectedList") && (this.getData("cxPropReqForSelected")!=undefined && this.getData("cxPropReqForSelected")!=null && this.getData("cxPropReqForSelected")==false ) ){
					this.setData('pageNo',0) //NO I18n
				}
			}
		 }
	 Lyte.arrayUtils(this.getData('localData'), 'concat', arg); //NO I18n
		 if(this.getData("cxPropCustomPagination") == undefined || this.getData("cxPropCustomPagination") == null || this.getData("cxPropCustomPagination") == false){
			 if((type=='userLookup' && this.getData('selectedList')==false)||(type!='userLookup')){
				 if(arg.length < this.getData('cxPropPerPage') ){ //NO I18n
				  this.setData('noMoreRecords', false); //NO I18n
				 }
			 }
		  else if((type=='userLookup' && this.getData('selectedList')==true)){
			  if(arg.length < this.getData('cxPropSelectedPerPage') ){ //NO I18n
				 this.setData('noMoreRecords', false); //NO I18n
			  }
		   }
		 }
	
		 this.constructArray.call(this, type, true);
		}
		else {
			if(this.getData("currentPos")==0){
				this.setData("systemData", []); //NO I18n
				// if(type=='userLookup'){
				// 	this.setData('noUsersAvilabel',true); //NO I18n
				// }
				this.setData('noUsersAvilabel',true); //NO I18n
		 }
		 else{
			 Lyte.arrayUtils(systemdata, 'push', []); //NO I18n
		 }
		 if(this.getData("cxPropCustomPagination") == undefined || this.getData("cxPropCustomPagination") == null || this.getData("cxPropCustomPagination") == false){
			this.setData('noMoreRecords', false); //NO I18n
		 }
		}
		if(this.loadMoreData){
			if(this.getData("cxPropCustomPagination") == undefined || this.getData("cxPropCustomPagination") == null || this.getData("cxPropCustomPagination") == false){
			 this.loadMoreData();
		 }else{
			 this.loadMoreData(orignialResponse);
		 }
		}
	},
	queryParams  : function(){
	 var ret = JSON.parse(JSON.stringify(this.getData('cxPropQueryParams') || {})); //NO I18n
	 if(this.setCriteria){
		 var ret = this.setCriteria();
	 }
	 if(ret==undefined){
		 ret=JSON.parse(JSON.stringify(this.getData('cxPropQueryParams') || {})); //NO I18n
	 }
	 // if(this.getData("isSearch")){
	 // 	ret.per_page = this.getData("cxPropSearchCount"); //NO I18n
	 // }
	 // else{
	 if(this.getData("cxPropCustomPagination") == undefined || this.getData("cxPropCustomPagination") == null || this.getData("cxPropCustomPagination") == false){
		 if(this.getData("cxPropPerPage")!=0){ //NO I18n
		 ret.per_page = this.getData("cxPropPerPage"); //NO I18n
	  }
	 // }
	 if(this.getData("pageNo")!=0){ //NO I18n
	 ret.page = this.getData("pageNo"); //NO I18n
	}
	}
	 return ret;
	},
	bodyScroll : function(type,event){
		if(!this.getData("stopScrolling")){
		var networkObject=this.getData("cxPropNetworkData"); //NO I18n
	 var body = event.target;
	 // if(body.scrollHeight <= (Math.ceil(body.offsetHeight) + Math.ceil(body.scrollTop)) && body._scrollEnd){
		 if(body.scrollHeight-10 <= (Math.ceil(body.offsetHeight) + Math.ceil(body.scrollTop))){
		 if(!this.getData("isSearch")){
			 // this.setData("cxPropCacheQuery",false); //NO I18n
			 // this.setData("cxPropDataCache",false); //NO I18n
			if(!this.getData("preventCacheReset")){
			 networkObject.cxPropCacheQuery=false;
			 networkObject.cxPropDataCache=false;
			 this.setData("cxPropNetworkData",networkObject); //NO I18n
			}
		 }
		 if(!this.getData('multiScroll')){
			 if(type=='userLookup'){
				 if(this.getData('noMoreRecords')){
					 if(!this.getData('selectedList')){
						 this.setData('searchingScroll',true); //NO I18n
					 }
					 else{
						  this.setData('searchingSelectedScroll',true); //NO I18n
					 }
				 }
			 }
			 else{
				 if(this.getData('noMoreRecords')){
					 this.setData('scrollLoading',true); //NO I18n
				 }else{
					 this.setData('scrollLoading',false); //NO I18n
				 }
			 }
		this.setData("multiScroll",true);//NO I18n
		this.constructArray.call(this,type,false);
	   }
	 }
	}
	else{
	this.setData("stopScrolling",false);//NO I18n
	}
	},
	keyup : function(type,setScroll,evt){
	 clearTimeout(this._timeout);
	 this._timeout = setTimeout(function(){
		 if(this.performKeyUpSearch){
			 this.performKeyUpSearch(evt , this.getData('cxPropInputValue'));  //NO I18n
		 }
	   var inputVal = this.getData('cxPropInputValue'), minLength = this.getData('cxPropMinLength'); //NO I18n
		 this.setData("currentSearchValue",inputVal);  //NO I18n
		 if(this.getData("noUsersAvilabel") && (this.getData("oldSearchValue")!=undefined || this.getData("oldSearchValue")!=null || this.getData("oldSearchValue")!="")){
	  if(this.getData("currentSearchValue").indexOf(this.getData("oldSearchValue"))==-1 || this.getData("currentSearchValue").indexOf(this.getData("oldSearchValue"))>0){
				this.setData("noUsersAvilabel",false); //NO I18n
				this.setData("noMoreRecords",true); //NO I18n
			}
		 }
		if(type=="userLookup"){
		// if(this.getData('cxPropInputValue').length==1){
	//  this.setData('searchingSearch',false);  //NO I18n
	// }
	// else
		if(this.getData('cxPropInputValue').length>minLength-1){
	  this.setData('searchingSearch',true);  //NO I18n
	}
		else{
			this.setData('searchingSearch',false);  //NO I18n
		}
	}
	  if(inputVal.length >= minLength){
			if(setScroll!=null){
			 this.setData("stopScrolling",true);//NO I18n
			 if(setScroll.scrollTop==0){
				 this.setData("stopScrolling",false);//NO I18n
			 }else{
				 setScroll.scrollTop=0;
			 }
		   }
			this.setData("multipleBack",false); //NO I18n
			if(this.getData("isSearch")!=true){
				this.setData("noMoreRecords",true);//NO I18n
			}
	   this.setData("isSearch", true); //NO I18n
		   if(type!="userLookup"){
				 // if(this.getData("noMoreRecords")){
					 this.setData("currentPos",0); //NO I18n
					 this.setData("localData", []); //NO I18n
					 if(this.getData("pageNo")!=0){//NO I18n
						this.setData("pageNo",1); //NO I18n
						}
				 }
	
			 // }
			 // if(this.getData("SearchNetworkCount")<5){
				//  this.setData("SearchNetworkCount",this.getData("SearchNetworkCount")+1); //NO I18n
				//  console.log(this.getData("SearchNetworkCount"));
				 if(type=="userLookup"){
					// this.setData('searchingSearch',true);  //NO I18n
					if(!this.getData("noUsersAvilabel")){
						this.filterObserver.call(this, 'search', evt); //NO I18n
					}
					else{
						this.setData("searchingSearch",false); //NO I18n
					}
			   }
				 else{
					 // if(this.getData('cxPropInputValue').length==1){
				 //  this.setData('searchLoading',false);  //NO I18n
				 // }
				 // else
					 if(this.getData('cxPropInputValue').length>minLength-1){
				   this.setData('searchLoading',true);  //NO I18n
				 }
					 else{
						 this.setData('searchLoading',false);  //NO I18n
					 }
					 // if(evt && evt.keyCode === 8 && this.getData("noUsersAvilabel")==true){
						//  this.setData("noUsersAvilabel",false);//NO I18n
						//  this.constructArray.call(this,type,false);
					 // }
					 // else
					 if(this.getData("noUsersAvilabel")==true){
						 this.setData('searchLoading',false);  //NO I18n
						 //this.constructArray.call(this,type,false);
					 }else{
						 this.constructArray.call(this,type,false);
					 }
				 }
	
			 // }
			 // else{
				//  this.setData("pendingSearchVariable",{isPending:true,type:type,evt:evt,val:inputVal}); //NO I18n
			 // }
	  }
		else{
			if(type!="userLookup"){
				this.setData('searchLoading',false);  //NO I18n
			}else{
				this.setData("searchingSearch",false); //NO I18n
			}
		}
		if(this.getData("multipleBack")==false||this.getData("multipleBack")==undefined){
			if(this.getData("multipleBack")==undefined && inputVal.length<this.getData('cxPropMinLength')){
				this.setData("multipleBack",true); //NO I18n
			}
			else{
	  if(inputVal.length == 0) {
			if(this.endKeyUpSearch){
				this.endKeyUpSearch();
			}
		 this.setData("isSearch", false); //NO I18n
		 if(type=="userLookup"){
			 this.setData("noMoreRecords",true); //NO I18n
		 //this.filterObserver.call(this,undefined,evt);
	 this.filterObserver.call(this,"removeAll",evt);//NO I18n
	   }
		 else{
			 this.setData("noMoreRecords",true); //NO I18n
			 this.setData("currentPos",0); //NO I18n
			 this.setData("localData", []); //NO I18n
			 if(this.getData("pageNo")!=0){//NO I18n
				this.setData("pageNo",1); //NO I18n
				}
			 this.constructArray.call(this,type,false)
		 }
		 this.setData("multipleBack",true); //NO I18n
	  }
	}
	 }
	 this.setData("oldSearchValue",this.getData("currentSearchValue"));  //NO I18n
	}.bind(this), 300);
	},
	escapeString : function(value){
	var returnValue=value.replace(/\(/g,'\\(').replace(/\)/g,'\\)');//NO I18n
	returnValue=returnValue.trim();
	return returnValue;
	},
	cxPropMaxLimitMessageBox : function(){
		var type = this.getData("messageBoxType");//No I18N
		if(!type){
			type = "warning";//No I18N
		}
		var message = this.getData("cxPropMaxLimitMsg"); //No I18N
		if(!message){
			message = _cruxUtils.getI18n("crux.comboBox.max.limit", this.getData("cxPropMaxLimit"), _cruxUtils.getI18n("crm.label.users")); //No I18N
		}
		var duration = this.getData("cxPropMaxLimitDuration"); //No I18N
		if(!duration){
			duration = "2000"; //No I18N
		}
		_cruxUtils.showCustomMessage({
							params: {
								ltPropType: type,
								ltPropMessage: message,
								ltPropDuration: duration,
								ltPropShow: true
							}
						});
	},
	getDefaultFilters: function() {
		var filters = [
			{"id": "AllUsers", "category": _cruxUtils.getI18n('crm.globalsearch.option.all')}, //NO I18n
			{"id": "ActiveUsers", "category": _cruxUtils.getI18n('webform.status.Active')}, //NO I18n
			{"id": "DeactiveUsers", "category": _cruxUtils.getI18n('Inactive')}, //NO I18n
			{"id": "ConfirmedUsers", "category": _cruxUtils.getI18n('Confirmed')}, //NO I18n
			{"id": "NotConfirmedUsers", "category": _cruxUtils.getI18n('crm.user.component.unconfirmed')}, //NO I18n
			{"id": "DeletedUsers", "category": _cruxUtils.getI18n('DeletedUser')}, //NO I18n
			{"id": "ActiveConfirmedUsers", "category": _cruxUtils.getI18n('webform.status.Active')+" "+_cruxUtils.getI18n('Confirmed')}, //NO I18n
			{"id": "AdminUsers", "category": _cruxUtils.getI18n('crm.feed.group.admin')}, //NO I18n
			{"id": "ActiveConfirmedAdmins", "category": _cruxUtils.getI18n('crm.user.label.active.confirmed.admins')}, //NO I18n
			{"id": "CurrentUser", "category": _cruxUtils.getI18n('crm.ln.lable.current')} //NO I18n
		];
	
		return filters;
	},
	/*Multi lazy load code*/
	/*lazyLoad: {*/

		getInstance: function () {
			return {
				/*Properties required to be set by user*/
				properties: {
					/*The details as to the type of request which is to be made is to be passed here [WORKS ONLY FOR STORE REQUESTS]
						E.g :- { networkDetails : ["user" , true , false, {test : "tested value"}] , requestType : "findAll" }
									 { networkDetails : ["userAction" , "actionName", "Custom data"] , requestType : "findAll" , .......}
						THE QUERY PARAMS WILL BE INSERTED BASED ON THE LOCATION OF QUERY PARAMS BASED ON REQUEST TYPE.
					*/
					requestDetails: {
						networkDetails: [],
						requestType: "findAll" //NO I18N
					},

					/*Same as above the details as to type of search request is to be made is to be passed here [WORKS ONLY FOR STORE REQUESTS]
						E.g :- { networkDetails : ["user" , true , false, {test : "tested value"}] , requestType : "findAll" }
									 { networkDetails : ["userAction" , "actionName", "Custom data"] , requestType : "findAll" , .......}
						THE QUERY PARAMS WILL BE INSERTED BASED ON THE LOCATION OF QUERY PARAMS BASED ON REQUEST TYPE.
					*/
					searchRequestDetails: {
						networkDetails: [],
						requestType: "triggerAction" //NO I18N
					},

					/*When the response is an object the key name to parse the response will be required*/
					parseResponseKey: {
						normal: "", //NO I18N
						search: "" //NO I18N
					},

					/*Properties with default values*/

					/*A list of all the records currently fetched*/
					allAvailableRecords: [],

					/*A list of records which contains the records ready for fed to DOM*/
					displayRecords: [],

					/*A variable pointing to the latest page number already requested for*/
					pageNo: 1,

					/*A variable which refers to the number of records required whenever a request to server is made*/
					perPage: 200,

					/*A boolean to be set to true when all the records from the server is fetched*/
					allRecordsFetched: false,

					/*A number which refere to the number of records to be fed to the DOM during each records fetch*/
					batchSize: 20,

					/*a string which refers to the primary key of the record [required to identify the record to be excluded]*/
					primaryKey: '', //NO I18N

					/*The object passed in this will be taken and included into the queryParams to the request*/
					queryParams: {},

					/*This should be a list of values where each value should be stored under the key pointed to by primaryKey variable*/
					excludeList: [],

					/*When a value to be excluded is encounterd it will be added to this list*/
					currentlyEcludedList: [],

					/*When the pagination is different from than what is currently used in mixin , set this and values like pageNo,perpage will not be set in request*/
					customPagination: false,

					/*When a non store request or a request which cannot be handled is required by the mixin this can be set , when set then a promise is to be returned which can be used to make any request as required
					 */
					customRequest: false,

					/*For non paginated data where entire value is returned from server cases*/
					isNonPagination: false,

					/*A boolean to to identify if current request is a search request or not*/
					isSearchReq: false,

					/*A string which should be passed with the value in typed by user*/
					searchValue: "", //NO I18N

					/*A string which will store the text details as to the kind of data currently being requested for
						{initial | scroll | search}
					*/
					currentLoadingStage: "", //NO I18N

					/*The minimum length for search to trigger*/
					triggerSearchLength: 2,

					/*A boolean to be set to true when all the records from the server is fetched and when all data is scrolled to and displayed*/
					allRecordsFetchedAndConsumed: false,

					/*A boolean to mention lazy loading scroll type i.e bottom to top or top to bottom*/
					reverseLazyLoadScroll: false

				},

				/*Callbacks*/
				callBacks: {
					queryParamsFunc: undefined,
					beforeNextBatchFunc: undefined,
					nextBatchFunc: undefined,
					customRequestFunc: undefined,
					beforeRequestFunc: undefined,
					afterRequestFunc: undefined,
					handleErrReqFunc: undefined,
					loadingStateUpdateFunc: undefined,
					invalidResponseFunc: undefined,
					beforeSearchRequestFunc: undefined
				},

				/*InternalVariables*/
				_localVariables: {
					currentRecordCount: 0,
					preventScrollFuncnalityOnLoad: false,
					preventScrollFuncnality: false,
					allAvailablePages: {
						currLen: 0
					},
					allAvailableBatchs: {
						currLen: 0,
						/*Not required available from variable currentRecordCount */
						batchCount: 0
					},
					latestBatch: 0,
					previousLoadingStage: "", //NO I18N
					scrollTimeout: undefined,
					searchTimeout: undefined,
					currentSearchValue: "", //NO I18N
					oldSearchValue: "", //NO I18N
					multipleBack: true,
					emptySearchResp: false
				},

				/*Any custom data user wants to pass*/
				userCustomData: {}
			}
		},

		performExclude: function (instance, recordsCount) {
			var primaryKey = instance.properties.primaryKey;
			var finalBatch = [];
			var cxPropExclude = instance.properties.excludeList;
			var allReclength = instance.properties.allAvailableRecords.length;
			var i;
			for (i = instance._localVariables.currentRecordCount; i < allReclength; i++) {
				if (finalBatch.length === recordsCount) {
					break;
				} else if (!primaryKey || cxPropExclude.indexOf(instance.properties.allAvailableRecords[i][primaryKey]) === -1) {
						finalBatch.push(instance.properties.allAvailableRecords[i]);
					} else {
						instance.properties.currentlyEcludedList.push(instance.properties.allAvailableRecords[i])
					}
			}
			/*So a batch of sixe recordsCount is to be constructed so will run through instance.properties.allAvailableRecords
				from current position but is enough is not found but all record are not fetched from server then will
				need to make a new request to server.
			*/
			if (i === length && finalBatch.length !== recordsCount && !instance.properties.allRecordsFetched) {
				this.constructNextBatch(instance, false, instance.properties.currentLoadingStage);
				return "nextRequest"; //NO I18N
			} else {
				/*Now when the batch is ready then will throw a callback to the user to reconstruct the batch to their licking.
					If they do not return the proper number of record then will try to reconstruct the batch to the proper size.
				*/
				instance._localVariables.currentRecordCount = i;
				if (i === length && instance._localVariables.allRecordsFetched) {
					instance.properties.allRecordsFetchedAndConsumed = true;
				}
				if (instance.callBacks.beforeNextBatchFunc) {
					var result = instance.callBacks.beforeNextBatchFunc(instance, finalBatch);
					if (result) {
						if (result.length < recordsCount) {
							return this.performExclude(instance, recordsCount);
						} else {
							return finalBatch;
						}
					} else {
						return finalBatch;
					}
				} else {
					return finalBatch;
				}
			}
		},

		constructNextBatch: function (instance, preventFutherReq, from) {
			if (from === undefined) {
				instance.properties.currentLoadingStage = "initial"; //NO I18N
				instance._localVariables.previousLoadingStage = "initial"; //NO I18N
				if (instance.callBacks.loadingStateUpdateFunc) {
					instance.callBacks.loadingStateUpdateFunc(instance, instance.properties.currentLoadingStage);
				}
			}
			var allAvailableRecordsLen = instance.properties.allAvailableRecords.length;
			if (instance._localVariables.currentRecordCount < allAvailableRecordsLen) {
				var currentBatchSize = allAvailableRecordsLen - instance._localVariables.currentRecordCount > instance.properties.batchSize ? instance.properties.batchSize : allAvailableRecordsLen - instance._localVariables.currentRecordCount;
				var currentBatch = this.performExclude(instance, currentBatchSize);
				if (currentBatch !== "nextRequest") {
					instance._localVariables.allAvailableBatchs.batchCount++;
					instance._localVariables.allAvailableBatchs.currLen = /*currentBatch.length;*/ instance._localVariables.currentRecordCount;
					instance._localVariables.allAvailableBatchs[instance._localVariables.allAvailableBatchs.batchCount] = currentBatch;
					instance.properties.displayRecords = instance.properties.displayRecords.concat(currentBatch);
					if (instance.callBacks.nextBatchFunc) {
						instance.callBacks.nextBatchFunc(instance, currentBatch);
					}
					instance._localVariables.previousLoadingStage = instance.properties.currentLoadingStage;
					instance.properties.currentLoadingStage = ""; //NO I18N
					if (instance.callBacks.loadingStateUpdateFunc) {
						instance.callBacks.loadingStateUpdateFunc(instance, instance.properties.currentLoadingStage);
					}
					instance._localVariables.latestBatch++;
				}
				return true;
			} else if (!preventFutherReq && !instance.properties.allRecordsFetched) {
				if (instance.callBacks.beforeRequestFunc) {
					instance.callBacks.beforeRequestFunc(instance);
				}
				var fromSearch = false;
				if (instance.properties.isSearchReq) {
					fromSearch = true;
				}
				var qp = this.getQueryParams(instance);
				if (instance.properties.customRequest && instance.callBacks.customRequestFunc) {
					//var networkDetails;
					var custProm = instance.callBacks.customRequestFunc(instance, qp);
					if (custProm && custProm.then) {
						Promise.resolve(custProm).then(function (response) {
							this.handleResponse(instance, response, fromSearch);
						}.bind(this));
						return custProm;
					}
				} else {
					var reqDetails;
					if (!instance.properties.isSearchReq) {
						reqDetails = instance.properties.requestDetails;
					} else {
						reqDetails = instance.properties.searchRequestDetails;
					}
					var qpPos;
					if (reqDetails.requestType === "findAll") {
						qpPos = 1;
					} else if (reqDetails.requestType === "triggerAction") {
						qpPos = 3;
					}
					Lyte.arrayUtils(reqDetails.networkDetails, 'insertAt', qpPos, qp); //NO I18N
					return store[reqDetails.requestType].apply(store, reqDetails.networkDetails).then(function (response) {
						this.handleResponse(instance, response, fromSearch);
						var newRespObj = {
							response: response,
							instance: instance
						}
						return newRespObj;
					}.bind(this), function (err) {
						instance.requestState.requestState = 0;
						if (instance.properties.isSearchReq) {
							instance.properties.displayRecords = [];
							instance.properties.allAvailableRecords = [];
						}
						if (instance.callBacks.handleErrReqFunc) {
							instance.callBacks.handleErrReqFunc();
						} else {
							var q = console;
							q.error(err);
						}
						return false;
					}.bind(this));
				}
			} else {
				instance._localVariables.previousLoadingStage = instance.properties.currentLoadingStage;
				instance.properties.currentLoadingStage = ""; //NO I18N
				if (instance.callBacks.loadingStateUpdateFunc) {
					instance.callBacks.loadingStateUpdateFunc(instance, instance.properties.currentLoadingStage);
				}
			}
		},

		getQueryParams: function (instance) {
			var qp = {},
				updatedQp = {};
			for (var key in instance.properties.queryParams) {
				qp[key] = instance.properties.queryParams[key];
			}
			if (!instance.properties.customPagination || !instance.properties.isNonPagination) {
				qp.per_page = instance.properties.perPage;
				qp.page = instance.properties.pageNo;
			}
			if (instance.callBacks.queryParamsFunc) {
				updatedQp = instance.callBacks.queryParamsFunc(instance, qp);
				if (!updatedQp || typeof updatedQp !== "object" && updatedQp.constructor === Object) {
					updatedQp = qp;
				}
			} else {
				updatedQp = qp;
			}
			return qp;
		},

		handleResponse: function (instance, response /*, fromSearch*/) {
			/*if (fromSearch && (instance.properties.currentLoadingStage === "initial" || instance.properties.currentLoadingStage === "")) {
				return;
			} else {*/
			var newResp;
			if (instance.callBacks.afterRequestFunc) {
				newResp = instance.callBacks.afterRequestFunc(instance, response);
				if (!newResp) {
					newResp = response;
				}
			} else {
				newResp = response;
			}
			if (!instance.properties.isSearchReq) {
				this.parseResponse(instance, newResp);
			} else if (instance.properties.searchValue !== "" || instance.properties.searchValue && instance.properties.searchValue.length !== 0) {
					this.parseResponse(instance, newResp);
				}
			/*}*/
		},

		parseResponse: function (instance, response) {
			var validResponse = true,
				responseParseKey, parsedResponse;
			if (instance.properties.isSearchReq) {
				responseParseKey = instance.properties.parseResponseKey.search;
			} else {
				responseParseKey = instance.properties.parseResponseKey.normal;
			}
			if (!response) {
				validResponse = false;
			} else if (response && response.constructor === Object && !response[responseParseKey]) {
				validResponse = false;
			} else if (Array.isArray(response) && response.length === 0) {
				validResponse = false;
			} else if (response[0] && response[0].$) {
				if (response[0].$.isError) {
					validResponse = false;
				}
			}
			if (validResponse) {
				if (response.constructor === Object) {
					parsedResponse = response[responseParseKey];
				} else if (Array.isArray(response)) {
					parsedResponse = response;
				} else {
					validResponse = false;
				}
				if (Array.isArray(parsedResponse) && parsedResponse.length === 0) {
					validResponse = false;
				}
			}
			if (validResponse) {
				if (instance.properties.isSearchReq) {
					instance._localVariables.emptySearchResp = false;
				}
				instance.properties.allAvailableRecords = instance.properties.allAvailableRecords.concat(parsedResponse);
				if (!instance.properties.customPagination || !instance.properties.isNonPagination) {
					instance.properties.pageNo++;
					if (parsedResponse.length < instance.properties.perPage) {
						instance.properties.allRecordsFetched = true;
					}
				} else if (instance.properties.isNonPagination) {
					instance.properties.allRecordsFetched = true;
				}

				instance._localVariables.allAvailablePages.currLen = instance.properties.allAvailableRecords.length;
				instance._localVariables.allAvailablePages[instance.properties.pageNo - 1] = parsedResponse;
				this.constructNextBatch(instance, true, instance.properties.currentLoadingStage);
			} else {
				if (instance.callBacks.invalidResponseFunc) {
					instance.callBacks.invalidResponseFunc(instance, response);
				}
				if (instance.properties.isSearchReq) {
					instance._localVariables.emptySearchResp = true;
				}
				instance.properties.allRecordsFetched = true;
				instance._localVariables.previousLoadingStage = instance.properties.currentLoadingStage;
				instance.properties.currentLoadingStage = ""; //NO I18N
				if (instance.callBacks.loadingStateUpdateFunc) {
					instance.callBacks.loadingStateUpdateFunc(instance, instance.properties.currentLoadingStage);
				}
			}
		},

		performScroll: function (instance, event) {
			if (!instance._localVariables.preventScrollFuncnality) {
				if (instance._localVariables.scrollTimeout) {
					clearTimeout(instance._localVariables.scrollTimeout);
				}
				instance._localVariables.scrollTimeout = setTimeout(function () {
					if (!instance._localVariables.preventScrollFuncnalityOnLoad) {
						var body = event.target;
						var scrollCondition;
						if (!instance.properties.reverseLazyLoadScroll) {
							scrollCondition = body.scrollHeight - 10 <= Math.ceil(body.offsetHeight) + Math.ceil(body.scrollTop);
						} else {
							scrollCondition = Math.ceil(body.scrollTop) <= 10;
						}
						if (scrollCondition) {
							instance._localVariables.preventScrollFuncnalityOnLoad = true;
							instance.properties.currentLoadingStage = "scroll"; //NO I18N
							if (instance.callBacks.loadingStateUpdateFunc) {
								instance.callBacks.loadingStateUpdateFunc(instance, instance.properties.currentLoadingStage);
							}
							var result = this.constructNextBatch(instance, false, "scroll"); //NO I18N
							if (result && result.then) {
								Promise.resolve(result).then(function (/*response*/) {
									instance._localVariables.preventScrollFuncnalityOnLoad = false;
								});
							} else {
								instance._localVariables.preventScrollFuncnalityOnLoad = false;
							}
						}
					}
				}.bind(this), 100);
			} else {
				instance._localVariables.preventScrollFuncnality = false;
			}
		},

		performSearch: function (instance, scrollEle) {
			if (instance._localVariables.searchTimeout) {
				clearTimeout(instance._localVariables.searchTimeout);
			}
			instance._localVariables.searchTimeout = setTimeout(function () {
				var inputVal = instance.properties.searchValue,
					minLength = instance.properties.triggerSearchLength;
				instance._localVariables.currentSearchValue = inputVal;
				if (instance._localVariables.emptySearchResp && instance._localVariables.oldSearchValue) {
					var indexVal = instance._localVariables.currentSearchValue.indexOf(instance._localVariables.oldSearchValue);
					if (indexVal === -1 || indexVal > 0) {
						instance._localVariables.emptySearchResp = false;
						instance.properties.allRecordsFetched = false;
						instance.properties.allRecordsFetchedAndConsumed = false;
					}
				}
				if (inputVal.length >= minLength) {
					if (!instance._localVariables.emptySearchResp) {
						if (scrollEle) {
							if (scrollEle.scrollTop > 0) {
								instance._localVariables.preventScrollFuncnality = true;
								scrollEle.scrollTop = 0;
							}
						}
						instance._localVariables.multipleBack = false;
						/*if (!instance.properties.isSearchReq) {*/
						instance.properties.allRecordsFetched = false;
						instance.properties.allRecordsFetchedAndConsumed = false;
						/*}*/
						instance.properties.isSearchReq = true;
						instance._localVariables.currentRecordCount = 0;
						instance.properties.allAvailableRecords = [];
						instance._localVariables.allAvailablePages = {
							currLen: 0
						};
						instance._localVariables.allAvailableBatchs = {
							currLen: 0,
							batchCount: 0
						};
						instance._localVariables.latestBatch = 0;
						instance.properties.displayRecords = [];
						instance.properties.pageNo = 1;
						instance.properties.currentLoadingStage = "search"; //NO I18N
						if (instance.callBacks.loadingStateUpdateFunc) {
							instance.callBacks.loadingStateUpdateFunc(instance, instance.properties.currentLoadingStage);
						}
						if (instance.callBacks.beforeSearchRequestFunc) {
							instance.callBacks.beforeSearchRequestFunc(instance, "beforSearch"); //NO I18N
						}
						var prom = this.constructNextBatch(instance, false, "search"); //NO I18N
						instance._localVariables.oldSearchValue = instance._localVariables.currentSearchValue;
						return prom;
					} else if (instance.callBacks.beforeSearchRequestFunc) {
							instance.callBacks.beforeSearchRequestFunc(instance, "rejected"); //NO I18N
						}
				}
				if (inputVal.length === 0) {
					if (!instance._localVariables.multipleBack) {
						instance.properties.allRecordsFetched = false;
						instance._localVariables.multipleBack = true;
						instance.properties.isSearchReq = false;
						instance._localVariables.currentRecordCount = 0;
						instance.properties.allAvailableRecords = [];
						instance._localVariables.allAvailablePages = {
							currLen: 0
						};
						instance._localVariables.allAvailableBatchs = {
							currLen: 0,
							batchCount: 0
						};
						instance._localVariables.latestBatch = 0;
						instance.properties.displayRecords = [];
						instance.properties.pageNo = 1;
						instance._localVariables.emptySearchResp = false;
						instance.properties.currentLoadingStage = "initial"; //NO I18N
						if (instance.callBacks.loadingStateUpdateFunc) {
							instance.callBacks.loadingStateUpdateFunc(instance, instance.properties.currentLoadingStage);
						}
						if (instance.callBacks.beforeSearchRequestFunc) {
							instance.callBacks.beforeSearchRequestFunc(instance, "clearSearch"); //NO I18N
						}
						var prom = this.constructNextBatch(instance, false, "initial"); //NO I18N
						instance._localVariables.oldSearchValue = "";
						return prom;
					}
				}

			}.bind(this), 300);
		},

		resetSearch: function (instance) {
			instance.properties.allRecordsFetched = false;
			instance._localVariables.multipleBack = true;
			instance.properties.isSearchReq = false;
			instance._localVariables.currentRecordCount = 0;
			instance.properties.allAvailableRecords = [];
			instance._localVariables.allAvailablePages = {
				currLen: 0
			};
			instance._localVariables.allAvailableBatchs = {
				currLen: 0,
				batchCount: 0
			};
			instance._localVariables.latestBatch = 0;
			instance.properties.displayRecords = [];
			instance.properties.pageNo = 1;
			instance.properties.currentLoadingStage = "initial"; //NO I18N
			instance._localVariables.emptySearchResp = false;
			if (instance.callBacks.loadingStateUpdateFunc) {
				instance.callBacks.loadingStateUpdateFunc(instance, instance.properties.currentLoadingStage);
			}
			if (instance.callBacks.beforeSearchRequestFunc) {
				instance.callBacks.beforeSearchRequestFunc(instance, "clearSearch"); //NO I18N
			}
			var prom = this.constructNextBatch(instance, false, "initial"); //NO I18N
			instance._localVariables.oldSearchValue = "";
			return prom;
		}
	/*}*/
});

//REVAMP 
// Lyte.Mixin.register("crux-user-utils", { //No I18n
// 	excludeIds: function (type, records, currentPos, recordsCount) {
// 		var primaryKey = this.getData("cxPropPrimaryKey"); //NO I18n
// 		var tempArr = [];
// 		var cxPropExclude = this.getData("cxPropExclude"); //NO I18n
// 		var recLength = records.length;
// 		for (var i = currentPos; i < recLength; i++) {
// 			if (tempArr.length === recordsCount) {
// 				break;
// 			} else if (cxPropExclude.indexOf(records[i][primaryKey]) === -1) {
// 				tempArr.push(records[i]);
// 			} else {
// 				Lyte.arrayUtils(this.getData("currentlyExcludedArray"), 'push', records[i]); //NO I18n
// 			}
// 		}
// 		this.setData('currentPos', i); //NO I18n
// 		if (this.newConstructedBatch) {
// 			this.newConstructedBatch(tempArr);
// 		}
// 		return tempArr;
// 	},
// 	constructArray: function (type, stopRequest) {
// 		var networkObject = this.getData("cxPropNetworkData"); //NO I18n
// 		var records;
// 		records = this.getData('localData'); //NO I18n
// 		if (this.getData("cxPropCustomPagination") === undefined || this.getData("cxPropCustomPagination") === null || this.getData("cxPropCustomPagination") === false) {
// 			if (type !== 'userLookup' || type === 'userLookup' && this.getData("selectedList") === false) {
// 				if (this.getData("pageNo") === 1 || this.getData("pageNo") === 0) {
// 					this.setData('noMoreRecords', true); //NO I18n
// 				}
// 			} else if (type === 'userLookup' && this.getData("selectedList") === true) {
// 				if (this.getData("pageNo") === 1) {
// 					this.setData('noMoreRecords', true); //NO I18n
// 				}
// 			}
// 		}
// 		var recordsCount = this.getData('cxPropRecords'); //NO I18n
// 		var currentPos = this.getData('currentPos'); //NO I18n
// 		var systemdata, addeddata;
// 		if (type === "userLookup" || type === "lookupComponent") {
// 			if (this.getData('selectedList') === false || this.getData('selectedList') === undefined) {
// 				systemdata = this.getData('systemData'); //NO I18n
// 			} else {
// 				addeddata = this.getData('addedItems'); //NO I18n
// 			}
// 		} else {
// 			systemdata = this.getData('systemData'); //NO I18n
// 		}
// 		if (currentPos < records.length) {
// 			recordsCount = records.length - currentPos > recordsCount ? recordsCount : records.length - currentPos;
// 			if (type === "userLookup" || type === "lookupComponent") {
// 				if (this.getData('selectedList') === false || this.getData('selectedList') === undefined) {
// 					if (currentPos === 0) {
// 						this.setData("systemData", this.excludeIds.call(this, type, records, currentPos, recordsCount)); //NO I18n
// 					} else {
// 						Lyte.arrayUtils(systemdata, 'push', this.excludeIds.call(this, type, records, currentPos, recordsCount)); //NO I18n
// 					}
// 				} else if (currentPos === 0) {
// 					this.setData("addedItems", this.excludeIds.call(this, type, records, currentPos, recordsCount)); //NO I18n
// 				} else {
// 					Lyte.arrayUtils(addeddata, 'push', this.excludeIds.call(this, type, records, currentPos, recordsCount)); //NO I18n
// 				}
// 			} else {
// 				if (currentPos === 0) {
// 					this.setData("systemData", this.excludeIds.call(this, type, records, currentPos, recordsCount)); //NO I18n
// 				} else {
// 					Lyte.arrayUtils(systemdata, 'push', this.excludeIds.call(this, type, records, currentPos, recordsCount)); //NO I18n
// 				}
// 				this.setData("scrollLoading", false); //NO I18n
// 			}
// 			this.setData("multiScroll", false); //NO I18n
// 			if (this.updatedList) {
// 				this.updatedList();
// 			}
// 		} else if (!stopRequest) {
// 			if (this.getData('noMoreRecords')) { //NO I18n
// 				if (this.getData("cxPropCustomRequest")) {
// 					if (this.customReqFunc) {
// 						var parameters = this.queryParams();
// 						var retVal = this.customReqFunc(this.getData("cxPropNetworkData"), parameters, this.getData("isSearch"), this.getData("cxPropInputValue")) //NO I18n
// 						if (retVal) {
// 							if (retVal.then) {
// 								Promise.resolve(retVal).then(function (arg) {
// 									if (!this.getData("isSearch")) {
// 										this.setReqData(arg, type);
// 									} else if (this.getData("cxPropInputValue")) {
// 										if (this.getData("cxPropInputValue").length !== 0) {
// 											this.setReqData(arg, type);
// 										}
// 									} else {
// 										this.setReqData(arg, type);
// 									}
// 								}.bind(this));
// 							}
// 						}
// 					}
// 				} else if (!this.getData("isSearch")) {
// 					return store.findAll(networkObject.cxPropModuleName, this.queryParams(), networkObject.cxPropCacheQuery, networkObject.cxPropDataCache, networkObject.cxPropCustomData).then(function (arg) { //NO I18n
// 						this.setReqData(arg, type);
// 					}.bind(this), function (err) {
// 						if (this.errorOnRequest) {
// 							if (type !== "userLookup") {
// 								this.setData("searchLoading", false); //NO I18n
// 								this.setData("scrollLoading", false); //NO I18n
// 								this.setData("systemData", []); //NO I18n
// 							}
// 							this.errorOnRequest();
// 						}
// 						var q = console;
// 						q.error(err);
// 						this.setData("multiScroll", false); //NO I18n
// 					}.bind(this));
// 				} else {
// 					return store.triggerAction(networkObject.cxPropModuleName, "search", this.queryParams()).then(function (arg) {
// 						if (this.getData("cxPropInputValue") === "" || this.getData("cxPropInputValue")) {
// 							if (this.getData("cxPropInputValue").length !== 0) {
// 								this.setReqData(arg, type);
// 							}
// 						} else {
// 							this.setReqData(arg, type);
// 						}

// 					}.bind(this), function (err) {
// 						if (this.errorOnSearch) {
// 							if (type !== "userLookup") {
// 								this.setData("searchLoading", false); //NO I18n
// 								this.setData("scrollLoading", false); //NO I18n
// 								this.setData("systemData", []); //NO I18n
// 							}
// 							this.errorOnSearch();
// 						}
// 						var q = console
// 						q.error(err);
// 						this.setData("multiScroll", false); //NO I18n
// 					}.bind(this));
// 				}
// 			} else {
// 				this.setData("multiScroll", false); //NO I18n
// 			}
// 		} else {
// 			this.setData("multiScroll", false); //NO I18n
// 		}
// 		return true;
// 	},
// 	setReqData: function (arg, type) {
// 		var orignialResponse = arg;
// 		var allowThrough = true;
// 		var networkObject = this.getData("cxPropNetworkData"); //NO I18n
// 		if (this.getData("isSearch")) {
// 			var objectKey = store.serializer[networkObject.cxPropModuleName].varPayloadKey;
// 		}
// 		var systemdata = this.getData('systemData'); //NO I18n
// 		this.setData("multiScroll", false); //NO I18n
// 		if (this.getData("isSearch")) {
// 			if (arg && arg.constructor === Object) {
// 				arg = arg[objectKey];
// 			} else if (Array.isArray(arg) && arg.length === 0) {
// 				allowThrough = false;
// 			}
// 		} else if (arg && arg[networkObject.cxPropModuleName] !== undefined) {
// 			arg = arg[networkObject.cxPropModuleName];
// 		} else if (arg && store.serializer[networkObject.cxPropModuleName] && arg[store.serializer[networkObject.cxPropModuleName].varPayloadKey] !== undefined) {
// 			arg = arg[store.serializer[networkObject.cxPropModuleName].varPayloadKey];
// 		}
// 		if (arg === undefined) {
// 			allowThrough = false;
// 		} else if (arg && !Array.isArray(arg) && arg[networkObject.cxPropModuleName] === undefined) {
// 			allowThrough = false;
// 		} else if (Array.isArray(arg) && arg.length === 0) {
// 			allowThrough = false;
// 		} else if (arg[0] && arg[0].$) {
// 			if (arg[0].$.isError) {
// 				allowThrough = false;
// 			}
// 		}
// 		if (arg && allowThrough) {
// 			if (this.getData("cxPropCustomPagination") === undefined || this.getData("cxPropCustomPagination") === null || this.getData("cxPropCustomPagination") === false) {
// 				this.setData('pageNo', this.getData('pageNo') + 1) //NO I18n
// 			}
// 			this.setData('noUsersAvilabel', false); //NO I18n
// 			if (this.getData("cxPropCustomPagination") === undefined || this.getData("cxPropCustomPagination") === null || this.getData("cxPropCustomPagination") === false) {
// 				if (type === 'userLookup') {
// 					if (this.getData("selectedList") && (this.getData("cxPropReqForSelected") !== undefined && this.getData("cxPropReqForSelected") !== null && this.getData("cxPropReqForSelected") === false)) {
// 						this.setData('pageNo', 0) //NO I18n
// 					}
// 				}
// 			}
// 			Lyte.arrayUtils(this.getData('localData'), 'concat', arg); //NO I18n
// 			if (this.getData("cxPropCustomPagination") === undefined || this.getData("cxPropCustomPagination") === null || this.getData("cxPropCustomPagination") === false) {
// 				if (type === 'userLookup' && this.getData('selectedList') === false || type !== 'userLookup') {
// 					if (arg.length < this.getData('cxPropPerPage')) { //NO I18n
// 						this.setData('noMoreRecords', false); //NO I18n
// 					}
// 				} else if (type === 'userLookup' && this.getData('selectedList') === true) {
// 					if (arg.length < this.getData('cxPropSelectedPerPage')) { //NO I18n
// 						this.setData('noMoreRecords', false); //NO I18n
// 					}
// 				}
// 			}

// 			this.constructArray.call(this, type, true);
// 		} else {
// 			if (this.getData("currentPos") === 0) {
// 				this.setData("systemData", []); //NO I18n
// 				this.setData('noUsersAvilabel', true); //NO I18n
// 			} else {
// 				Lyte.arrayUtils(systemdata, 'push', []); //NO I18n
// 			}
// 			if (this.getData("cxPropCustomPagination") === undefined || this.getData("cxPropCustomPagination") === null || this.getData("cxPropCustomPagination") === false) {
// 				this.setData('noMoreRecords', false); //NO I18n
// 			}
// 		}
// 		if (this.loadMoreData) {
// 			if (this.getData("cxPropCustomPagination") === undefined || this.getData("cxPropCustomPagination") === null || this.getData("cxPropCustomPagination") === false) {
// 				this.loadMoreData();
// 			} else {
// 				this.loadMoreData(orignialResponse);
// 			}
// 		}
// 	},
// 	queryParams: function () {
// 		var ret = JSON.parse(JSON.stringify(this.getData('cxPropQueryParams') || {})); //NO I18n
// 		if (this.setCriteria) {
// 			var ret = this.setCriteria();
// 		}
// 		if (ret === undefined) {
// 			ret = JSON.parse(JSON.stringify(this.getData('cxPropQueryParams') || {})); //NO I18n
// 		}
// 		if (this.getData("cxPropCustomPagination") === undefined || this.getData("cxPropCustomPagination") === null || this.getData("cxPropCustomPagination") === false) {
// 			if (this.getData("cxPropPerPage") !== 0) { //NO I18n
// 				ret.per_page = this.getData("cxPropPerPage"); //NO I18n
// 			}
// 			if (this.getData("pageNo") !== 0) { //NO I18n
// 				ret.page = this.getData("pageNo"); //NO I18n
// 			}
// 		}
// 		return ret;
// 	},
// 	bodyScroll: function (type, event) {
// 		if (!this.getData("stopScrolling")) {
// 			var networkObject = this.getData("cxPropNetworkData"); //NO I18n
// 			var body = event.target;
// 			if (body.scrollHeight - 10 <= Math.ceil(body.offsetHeight) + Math.ceil(body.scrollTop)) {
// 				if (!this.getData("isSearch")) {
// 					if (!this.getData("preventCacheReset")) {
// 						networkObject.cxPropCacheQuery = false;
// 						networkObject.cxPropDataCache = false;
// 						this.setData("cxPropNetworkData", networkObject); //NO I18n
// 					}
// 				}
// 				if (!this.getData('multiScroll')) {
// 					if (type === 'userLookup') {
// 						if (this.getData('noMoreRecords')) {
// 							if (!this.getData('selectedList')) {
// 								this.setData('searchingScroll', true); //NO I18n
// 							} else {
// 								this.setData('searchingSelectedScroll', true); //NO I18n
// 							}
// 						}
// 					} else if (this.getData('noMoreRecords')) {
// 						this.setData('scrollLoading', true); //NO I18n
// 					} else {
// 						this.setData('scrollLoading', false); //NO I18n
// 					}
// 					this.setData("multiScroll", true); //NO I18n
// 					this.constructArray.call(this, type, false);
// 				}
// 			}
// 		} else {
// 			this.setData("stopScrolling", false); //NO I18n
// 		}
// 	},
// 	keyup: function (type, setScroll, evt) {
// 		clearTimeout(this._timeout);
// 		this._timeout = setTimeout(function () {
// 			if (this.performKeyUpSearch) {
// 				this.performKeyUpSearch(evt, this.getData('cxPropInputValue')); //NO I18n
// 			}
// 			var inputVal = this.getData('cxPropInputValue'),
// 				minLength = this.getData('cxPropMinLength'); //NO I18n
// 			this.setData("currentSearchValue", inputVal); //NO I18n
// 			if (this.getData("noUsersAvilabel") && (this.getData("oldSearchValue") !== undefined || this.getData("oldSearchValue") !== null || this.getData("oldSearchValue") !== "")) {
// 				if (this.getData("currentSearchValue").indexOf(this.getData("oldSearchValue")) === -1 || this.getData("currentSearchValue").indexOf(this.getData("oldSearchValue")) > 0) {
// 					this.setData("noUsersAvilabel", false); //NO I18n
// 					this.setData("noMoreRecords", true); //NO I18n
// 				}
// 			}
// 			if (type === "userLookup") {
// 				if (this.getData('cxPropInputValue').length > minLength - 1) {
// 					this.setData('searchingSearch', true); //NO I18n
// 				} else {
// 					this.setData('searchingSearch', false); //NO I18n
// 				}
// 			}
// 			if (inputVal.length >= minLength) {
// 				if (setScroll !== null) {
// 					this.setData("stopScrolling", true); //NO I18n
// 					if (setScroll.scrollTop === 0) {
// 						this.setData("stopScrolling", false); //NO I18n
// 					} else {
// 						setScroll.scrollTop = 0;
// 					}
// 				}
// 				this.setData("multipleBack", false); //NO I18n
// 				if (this.getData("isSearch") !== true) {
// 					this.setData("noMoreRecords", true); //NO I18n
// 				}
// 				this.setData("isSearch", true); //NO I18n
// 				if (type !== "userLookup") {
// 					// if(this.getData("noMoreRecords")){
// 					this.setData("currentPos", 0); //NO I18n
// 					this.setData("localData", []); //NO I18n
// 					if (this.getData("pageNo") !== 0) { //NO I18n
// 						this.setData("pageNo", 1); //NO I18n
// 					}
// 				}
// 				if (type === "userLookup") {
// 					if (!this.getData("noUsersAvilabel")) {
// 						this.filterObserver.call(this, 'search', evt); //NO I18n
// 					} else {
// 						this.setData("searchingSearch", false); //NO I18n
// 					}
// 				} else {
// 					if (this.getData('cxPropInputValue').length > minLength - 1) {
// 						this.setData('searchLoading', true); //NO I18n
// 					} else {
// 						this.setData('searchLoading', false); //NO I18n
// 					}
// 					if (this.getData("noUsersAvilabel") === true) {
// 						this.setData('searchLoading', false); //NO I18n
// 					} else {
// 						this.constructArray.call(this, type, false);
// 					}
// 				}
// 			} else if (type !== "userLookup") {
// 				this.setData('searchLoading', false); //NO I18n
// 			} else {
// 				this.setData("searchingSearch", false); //NO I18n
// 			}
// 			if (this.getData("multipleBack") === false || this.getData("multipleBack") === undefined) {
// 				if (this.getData("multipleBack") === undefined && inputVal.length < this.getData('cxPropMinLength')) {
// 					this.setData("multipleBack", true); //NO I18n
// 				} else if (inputVal.length === 0) {
// 					if (this.endKeyUpSearch) {
// 						this.endKeyUpSearch();
// 					}
// 					this.setData("isSearch", false); //NO I18n
// 					this.setData("noMoreRecords", true); //NO I18n
// 					if (type === "userLookup") {
// 						this.filterObserver.call(this, "removeAll", evt); //NO I18n
// 					} else {
// 						this.setData("currentPos", 0); //NO I18n
// 						this.setData("localData", []); //NO I18n
// 						if (this.getData("pageNo") !== 0) { //NO I18n
// 							this.setData("pageNo", 1); //NO I18n
// 						}
// 						this.constructArray.call(this, type, false)
// 					}
// 					this.setData("multipleBack", true); //NO I18n
// 				}
// 			}
// 			this.setData("oldSearchValue", this.getData("currentSearchValue")); //NO I18n
// 		}.bind(this), 300);
// 	},
// 	escapeString: function (value) {
// 		var returnValue = value.replace(/\(/g, '\\(').replace(/\)/g, '\\)'); //NO I18n
// 		returnValue = returnValue.trim();
// 		return returnValue;
// 	},
// 	cxPropMaxLimitMessageBox: function () {
// 		var type = this.getData("messageBoxType"); //No I18N
// 		if (!type) {
// 			type = "warning"; //No I18N
// 		}
// 		var message = this.getData("cxPropMaxLimitMsg"); //No I18N
// 		if (!message) {
// 			message = _cruxUtils.getI18n("crux.comboBox.max.limit", this.getData("cxPropMaxLimit"), _cruxUtils.getI18n("crm.label.users")); //No I18N
// 		}
// 		var duration = this.getData("cxPropMaxLimitDuration"); //No I18N
// 		if (!duration) {
// 			duration = "2000"; //No I18N
// 		}
// 		_cruxUtils.showCustomMessage({
// 			params: {
// 				ltPropType: type,
// 				ltPropMessage: message,
// 				ltPropDuration: duration,
// 				ltPropShow: true
// 			}
// 		});
// 	},
// 	getDefaultFilters: function () {
// 		var filters = [{
// 				"id": "AllUsers",
// 				"category": _cruxUtils.getI18n('crm.globalsearch.option.all')
// 			}, //NO I18n
// 			{
// 				"id": "ActiveUsers",
// 				"category": _cruxUtils.getI18n('webform.status.Active')
// 			}, //NO I18n
// 			{
// 				"id": "DeactiveUsers",
// 				"category": _cruxUtils.getI18n('Inactive')
// 			}, //NO I18n
// 			{
// 				"id": "ConfirmedUsers",
// 				"category": _cruxUtils.getI18n('Confirmed')
// 			}, //NO I18n
// 			{
// 				"id": "NotConfirmedUsers",
// 				"category": _cruxUtils.getI18n('crm.user.component.unconfirmed')
// 			}, //NO I18n
// 			{
// 				"id": "DeletedUsers",
// 				"category": _cruxUtils.getI18n('DeletedUser')
// 			}, //NO I18n
// 			{
// 				"id": "ActiveConfirmedUsers",
// 				"category": _cruxUtils.getI18n('webform.status.Active') + " " + _cruxUtils.getI18n('Confirmed')
// 			}, //NO I18n
// 			{
// 				"id": "AdminUsers",
// 				"category": _cruxUtils.getI18n('crm.feed.group.admin')
// 			}, //NO I18n
// 			{
// 				"id": "ActiveConfirmedAdmins",
// 				"category": _cruxUtils.getI18n('crm.user.label.active.confirmed.admins')
// 			}, //NO I18n
// 			{
// 				"id": "CurrentUser",
// 				"category": _cruxUtils.getI18n('crm.ln.lable.current')
// 			} //NO I18n
// 		];

// 		return filters;
// 	},
// 	/*Multi lazy load code*/
// 	lazyLoad: {

// 		getInstance: function () {
// 			return {
// 				/*Properties required to be set by user*/
// 				properties: {
// 					/*The details as to the type of request which is to be made is to be passed here [WORKS ONLY FOR STORE REQUESTS]
// 						E.g :- { networkDetails : ["user" , true , false, {test : "tested value"}] , requestType : "findAll" }
// 									 { networkDetails : ["userAction" , "actionName", "Custom data"] , requestType : "findAll" , .......}
// 						THE QUERY PARAMS WILL BE INSERTED BASED ON THE LOCATION OF QUERY PARAMS BASED ON REQUEST TYPE.
// 					*/
// 					requestDetails: {
// 						networkDetails: [],
// 						requestType: "findAll" //NO I18N
// 					},

// 					/*Same as above the details as to type of search request is to be made is to be passed here [WORKS ONLY FOR STORE REQUESTS]
// 						E.g :- { networkDetails : ["user" , true , false, {test : "tested value"}] , requestType : "findAll" }
// 									 { networkDetails : ["userAction" , "actionName", "Custom data"] , requestType : "findAll" , .......}
// 						THE QUERY PARAMS WILL BE INSERTED BASED ON THE LOCATION OF QUERY PARAMS BASED ON REQUEST TYPE.
// 					*/
// 					searchRequestDetails: {
// 						networkDetails: [],
// 						requestType: "triggerAction" //NO I18N
// 					},

// 					/*When the response is an object the key name to parse the response will be required*/
// 					parseResponseKey: {
// 						normal: "", //NO I18N
// 						search: "" //NO I18N
// 					},

// 					/*Properties with default values*/

// 					/*A list of all the records currently fetched*/
// 					allAvailableRecords: [],

// 					/*A list of records which contains the records ready for fed to DOM*/
// 					displayRecords: [],

// 					/*A variable pointing to the latest page number already requested for*/
// 					pageNo: 1,

// 					/*A variable which refers to the number of records required whenever a request to server is made*/
// 					perPage: 200,

// 					/*A boolean to be set to true when all the records from the server is fetched*/
// 					allRecordsFetched: false,

// 					/*A number which refere to the number of records to be fed to the DOM during each records fetch*/
// 					batchSize: 20,

// 					/*a string which refers to the primary key of the record [required to identify the record to be excluded]*/
// 					primaryKey: '', //NO I18N

// 					/*The object passed in this will be taken and included into the queryParams to the request*/
// 					queryParams: {},

// 					/*This should be a list of values where each value should be stored under the key pointed to by primaryKey variable*/
// 					excludeList: [],

// 					/*When a value to be excluded is encounterd it will be added to this list*/
// 					currentlyEcludedList: [],

// 					/*When the pagination is different from than what is currently used in mixin , set this and values like pageNo,perpage will not be set in request*/
// 					customPagination: false,

// 					/*When a non store request or a request which cannot be handled is required by the mixin this can be set , when set then a promise is to be returned which can be used to make any request as required
// 					 */
// 					customRequest: false,

// 					/*For non paginated data where entire value is returned from server cases*/
// 					isNonPagination: false,

// 					/*A boolean to to identify if current request is a search request or not*/
// 					isSearchReq: false,

// 					/*A string which should be passed with the value in typed by user*/
// 					searchValue: "", //NO I18N

// 					/*A string which will store the text details as to the kind of data currently being requested for
// 						{initial | scroll | search}
// 					*/
// 					currentLoadingStage: "", //NO I18N

// 					/*The minimum length for search to trigger*/
// 					triggerSearchLength: 2,

// 					/*A boolean to be set to true when all the records from the server is fetched and when all data is scrolled to and displayed*/
// 					allRecordsFetchedAndConsumed: false,

// 					/*A boolean to mention lazy loading scroll type i.e bottom to top or top to bottom*/
// 					reverseLazyLoadScroll: false

// 				},

// 				/*Callbacks*/
// 				callBacks: {
// 					queryParamsFunc: undefined,
// 					beforeNextBatchFunc: undefined,
// 					nextBatchFunc: undefined,
// 					customRequestFunc: undefined,
// 					beforeRequestFunc: undefined,
// 					afterRequestFunc: undefined,
// 					handleErrReqFunc: undefined,
// 					loadingStateUpdateFunc: undefined,
// 					invalidResponseFunc: undefined,
// 					beforeSearchRequestFunc: undefined
// 				},

// 				/*InternalVariables*/
// 				_localVariables: {
// 					currentRecordCount: 0,
// 					preventScrollFuncnalityOnLoad: false,
// 					preventScrollFuncnality: false,
// 					allAvailablePages: {
// 						currLen: 0
// 					},
// 					allAvailableBatchs: {
// 						currLen: 0,
// 						/*Not required available from variable currentRecordCount */
// 						batchCount: 0
// 					},
// 					latestBatch: 0,
// 					previousLoadingStage: "", //NO I18N
// 					scrollTimeout: undefined,
// 					searchTimeout: undefined,
// 					currentSearchValue: "", //NO I18N
// 					oldSearchValue: "", //NO I18N
// 					multipleBack: true,
// 					emptySearchResp: false
// 				},

// 				/*Any custom data user wants to pass*/
// 				userCustomData: {}
// 			}
// 		},

// 		performExclude: function (instance, recordsCount) {
// 			var primaryKey = instance.properties.primaryKey;
// 			var finalBatch = [];
// 			var cxPropExclude = instance.properties.excludeList;
// 			var allReclength = instance.properties.allAvailableRecords.length;
// 			var i;
// 			for (i = instance._localVariables.currentRecordCount; i < allReclength; i++) {
// 				if (finalBatch.length === recordsCount) {
// 					break;
// 				} else if (!primaryKey || cxPropExclude.indexOf(instance.properties.allAvailableRecords[i][primaryKey]) === -1) {
// 					finalBatch.push(instance.properties.allAvailableRecords[i]);
// 				} else {
// 					instance.properties.currentlyEcludedList.push(instance.properties.allAvailableRecords[i])
// 				}
// 			}
// 			/*So a batch of sixe recordsCount is to be constructed so will run through instance.properties.allAvailableRecords
// 				from current position but is enough is not found but all record are not fetched from server then will
// 				need to make a new request to server.
// 			*/
// 			if (i === length && finalBatch.length !== recordsCount && !instance.properties.allRecordsFetched) {
// 				this.constructNextBatch(instance, false, instance.properties.currentLoadingStage);
// 				return "nextRequest"; //NO I18N
// 			} else {
// 				/*Now when the batch is ready then will throw a callback to the user to reconstruct the batch to their licking.
// 					If they do not return the proper number of record then will try to reconstruct the batch to the proper size.
// 				*/
// 				instance._localVariables.currentRecordCount = i;
// 				if (i === length && instance._localVariables.allRecordsFetched) {
// 					instance.properties.allRecordsFetchedAndConsumed = true;
// 				}
// 				if (instance.callBacks.beforeNextBatchFunc) {
// 					var result = instance.callBacks.beforeNextBatchFunc(instance, finalBatch);
// 					if (result) {
// 						if (result.length < recordsCount) {
// 							return this.performExclude(instance, recordsCount);
// 						} else {
// 							return finalBatch;
// 						}
// 					} else {
// 						return finalBatch;
// 					}
// 				} else {
// 					return finalBatch;
// 				}
// 			}
// 		},

// 		constructNextBatch: function (instance, preventFutherReq, from) {
// 			if (from === undefined) {
// 				instance.properties.currentLoadingStage = "initial"; //NO I18N
// 				instance._localVariables.previousLoadingStage = "initial"; //NO I18N
// 				if (instance.callBacks.loadingStateUpdateFunc) {
// 					instance.callBacks.loadingStateUpdateFunc(instance, instance.properties.currentLoadingStage);
// 				}
// 			}
// 			var allAvailableRecordsLen = instance.properties.allAvailableRecords.length;
// 			if (instance._localVariables.currentRecordCount < allAvailableRecordsLen) {
// 				var currentBatchSize = allAvailableRecordsLen - instance._localVariables.currentRecordCount > instance.properties.batchSize ? instance.properties.batchSize : allAvailableRecordsLen - instance._localVariables.currentRecordCount;
// 				var currentBatch = this.performExclude(instance, currentBatchSize);
// 				if (currentBatch !== "nextRequest") {
// 					instance._localVariables.allAvailableBatchs.batchCount++;
// 					instance._localVariables.allAvailableBatchs.currLen = /*currentBatch.length;*/ instance._localVariables.currentRecordCount;
// 					instance._localVariables.allAvailableBatchs[instance._localVariables.allAvailableBatchs.batchCount] = currentBatch;
// 					instance.properties.displayRecords = instance.properties.displayRecords.concat(currentBatch);
// 					if (instance.callBacks.nextBatchFunc) {
// 						instance.callBacks.nextBatchFunc(instance, currentBatch);
// 					}
// 					instance._localVariables.previousLoadingStage = instance.properties.currentLoadingStage;
// 					instance.properties.currentLoadingStage = ""; //NO I18N
// 					if (instance.callBacks.loadingStateUpdateFunc) {
// 						instance.callBacks.loadingStateUpdateFunc(instance, instance.properties.currentLoadingStage);
// 					}
// 					instance._localVariables.latestBatch++;
// 				}
// 				return true;
// 			} else if (!preventFutherReq && !instance.properties.allRecordsFetched) {
// 				if (instance.callBacks.beforeRequestFunc) {
// 					instance.callBacks.beforeRequestFunc(instance);
// 				}
// 				var fromSearch = false;
// 				if (instance.properties.isSearchReq) {
// 					fromSearch = true;
// 				}
// 				if (instance.properties.customRequest && instance.callBacks.customRequestFunc) {
// 					var qp = this.getQueryParams(instance);
// 					//var networkDetails;
// 					var custProm = instance.callBacks.customRequestFunc(instance, qp);
// 					if (custProm && custProm.then) {
// 						Promise.resolve(custProm).then(function (response) {
// 							this.handleResponse(instance, response, fromSearch);
// 						}.bind(this));
// 						return custProm;
// 					}
// 				} else {
// 					var reqDetails;
// 					if (!instance.properties.isSearchReq) {
// 						reqDetails = instance.properties.requestDetails;
// 					} else {
// 						reqDetails = instance.properties.searchRequestDetails;
// 					}
// 					var qpPos;
// 					if (reqDetails.requestType === "findAll") {
// 						qpPos = 1;
// 					} else if (reqDetails.requestType === "triggerAction") {
// 						qpPos = 3;
// 					}
// 					Lyte.arrayUtils(reqDetails.networkDetails, 'insertAt', qpPos, qp); //NO I18N
// 					return store[reqDetails.requestType].apply(store, reqDetails.networkDetails).then(function (response) {
// 						this.handleResponse(instance, response, fromSearch);
// 						var newRespObj = {
// 							response: response,
// 							instance: instance
// 						}
// 						return newRespObj;
// 					}.bind(this), function (err) {
// 						instance.requestState.requestState = 0;
// 						if (instance.properties.isSearchReq) {
// 							instance.properties.displayRecords = [];
// 							instance.properties.allAvailableRecords = [];
// 						}
// 						if (instance.callBacks.handleErrReqFunc) {
// 							instance.callBacks.handleErrReqFunc();
// 						} else {
// 							var q = console;
// 							q.error(err);
// 						}
// 						return false;
// 					}.bind(this));
// 				}
// 			} else {
// 				instance._localVariables.previousLoadingStage = instance.properties.currentLoadingStage;
// 				instance.properties.currentLoadingStage = ""; //NO I18N
// 				if (instance.callBacks.loadingStateUpdateFunc) {
// 					instance.callBacks.loadingStateUpdateFunc(instance, instance.properties.currentLoadingStage);
// 				}
// 			}
// 		},

// 		getQueryParams: function (instance) {
// 			var qp = {},
// 				updatedQp = {};
// 			for (var key in instance.properties.queryParams) {
// 				qp[key] = instance.properties.queryParams[key];
// 			}
// 			if (!instance.properties.customPagination || !instance.properties.isNonPagination) {
// 				qp.per_page = instance.properties.perPage;
// 				qp.page = instance.properties.pageNo;
// 			}
// 			if (instance.callBacks.queryParamsFunc) {
// 				updatedQp = instance.callBacks.queryParamsFunc(instance, qp);
// 				if (!updatedQp || typeof updatedQp !== "object" && updatedQp.constructor === Object) {
// 					updatedQp = qp;
// 				}
// 			} else {
// 				updatedQp = qp;
// 			}
// 			return qp;
// 		},

// 		handleResponse: function (instance, response /*, fromSearch*/ ) {
// 			/*if (fromSearch && (instance.properties.currentLoadingStage === "initial" || instance.properties.currentLoadingStage === "")) {
// 				return;
// 			} else {*/
// 			var newResp;
// 			if (instance.callBacks.afterRequestFunc) {
// 				newResp = instance.callBacks.afterRequestFunc(instance, response);
// 				if (!newResp) {
// 					newResp = response;
// 				}
// 			} else {
// 				newResp = response;
// 			}
// 			if (!instance.properties.isSearchReq) {
// 				this.parseResponse(instance, newResp);
// 			} else if (instance.properties.searchValue !== "" || instance.properties.searchValue && instance.properties.searchValue.length !== 0) {
// 				this.parseResponse(instance, newResp);
// 			}
// 			/*}*/
// 		},

// 		parseResponse: function (instance, response) {
// 			var validResponse = true,
// 				responseParseKey, parsedResponse;
// 			if (instance.properties.isSearchReq) {
// 				responseParseKey = instance.properties.parseResponseKey.search;
// 			} else {
// 				responseParseKey = instance.properties.parseResponseKey.normal;
// 			}
// 			if (!response) {
// 				validResponse = false;
// 			} else if (response && response.constructor === Object && !response[responseParseKey]) {
// 				validResponse = false;
// 			} else if (Array.isArray(response) && response.length === 0) {
// 				validResponse = false;
// 			} else if (response[0] && response[0].$) {
// 				if (response[0].$.isError) {
// 					validResponse = false;
// 				}
// 			}
// 			if (validResponse) {
// 				if (response.constructor === Object) {
// 					parsedResponse = response[responseParseKey];
// 				} else if (Array.isArray(response)) {
// 					parsedResponse = response;
// 				} else {
// 					validResponse = false;
// 				}
// 				if (Array.isArray(parsedResponse) && parsedResponse.length === 0) {
// 					validResponse = false;
// 				}
// 			}
// 			if (validResponse) {
// 				if (instance.properties.isSearchReq) {
// 					instance._localVariables.emptySearchResp = false;
// 				}
// 				instance.properties.allAvailableRecords = instance.properties.allAvailableRecords.concat(parsedResponse);
// 				if (!instance.properties.customPagination || !instance.properties.isNonPagination) {
// 					instance.properties.pageNo++;
// 					if (parsedResponse.length < instance.properties.perPage) {
// 						instance.properties.allRecordsFetched = true;
// 					}
// 				} else if (instance.properties.isNonPagination) {
// 					instance.properties.allRecordsFetched = true;
// 				}

// 				instance._localVariables.allAvailablePages.currLen = instance.properties.allAvailableRecords.length;
// 				instance._localVariables.allAvailablePages[instance.properties.pageNo - 1] = parsedResponse;
// 				this.constructNextBatch(instance, true, instance.properties.currentLoadingStage);
// 			} else {
// 				if (instance.callBacks.invalidResponseFunc) {
// 					instance.callBacks.invalidResponseFunc(instance, response);
// 				}
// 				if (instance.properties.isSearchReq) {
// 					instance._localVariables.emptySearchResp = true;
// 				}
// 				instance.properties.allRecordsFetched = true;
// 				instance._localVariables.previousLoadingStage = instance.properties.currentLoadingStage;
// 				instance.properties.currentLoadingStage = ""; //NO I18N
// 				if (instance.callBacks.loadingStateUpdateFunc) {
// 					instance.callBacks.loadingStateUpdateFunc(instance, instance.properties.currentLoadingStage);
// 				}
// 			}
// 		},

// 		performScroll: function (instance, event) {
// 			if (!instance._localVariables.preventScrollFuncnality) {
// 				if (instance._localVariables.scrollTimeout) {
// 					clearTimeout(instance._localVariables.scrollTimeout);
// 				}
// 				instance._localVariables.scrollTimeout = setTimeout(function () {
// 					if (!instance._localVariables.preventScrollFuncnalityOnLoad) {
// 						var body = event.target;
// 						var scrollCondition;
// 						if (!instance.properties.reverseLazyLoadScroll) {
// 							scrollCondition = body.scrollHeight - 10 <= Math.ceil(body.offsetHeight) + Math.ceil(body.scrollTop);
// 						} else {
// 							scrollCondition = Math.ceil(body.scrollTop) <= 10;
// 						}
// 						if (scrollCondition) {
// 							instance._localVariables.preventScrollFuncnalityOnLoad = true;
// 							instance.properties.currentLoadingStage = "scroll"; //NO I18N
// 							if (instance.callBacks.loadingStateUpdateFunc) {
// 								instance.callBacks.loadingStateUpdateFunc(instance, instance.properties.currentLoadingStage);
// 							}
// 							var result = this.constructNextBatch(instance, false, "scroll"); //NO I18N
// 							if (result && result.then) {
// 								Promise.resolve(result).then(function ( /*response*/ ) {
// 									instance._localVariables.preventScrollFuncnalityOnLoad = false;
// 								});
// 							} else {
// 								instance._localVariables.preventScrollFuncnalityOnLoad = false;
// 							}
// 						}
// 					}
// 				}.bind(this), 100);
// 			} else {
// 				instance._localVariables.preventScrollFuncnality = false;
// 			}
// 		},

// 		performSearch: function (instance, scrollEle) {
// 			if (instance._localVariables.searchTimeout) {
// 				clearTimeout(instance._localVariables.searchTimeout);
// 			}
// 			instance._localVariables.searchTimeout = setTimeout(function () {
// 				var inputVal = instance.properties.searchValue,
// 					minLength = instance.properties.triggerSearchLength;
// 				instance._localVariables.currentSearchValue = inputVal;
// 				if (instance._localVariables.emptySearchResp && instance._localVariables.oldSearchValue) {
// 					var indexVal = instance._localVariables.currentSearchValue.indexOf(instance._localVariables.oldSearchValue);
// 					if (indexVal === -1 || indexVal > 0) {
// 						instance._localVariables.emptySearchResp = false;
// 						instance.properties.allRecordsFetched = false;
// 						instance.properties.allRecordsFetchedAndConsumed = false;
// 					}
// 				}
// 				if (inputVal.length >= minLength) {
// 					if (!instance._localVariables.emptySearchResp) {
// 						if (scrollEle) {
// 							if (scrollEle.scrollTop > 0) {
// 								instance._localVariables.preventScrollFuncnality = true;
// 								scrollEle.scrollTop = 0;
// 							}
// 						}
// 						instance._localVariables.multipleBack = false;
// 						/*if (!instance.properties.isSearchReq) {*/
// 						instance.properties.allRecordsFetched = false;
// 						instance.properties.allRecordsFetchedAndConsumed = false;
// 						/*}*/
// 						instance.properties.isSearchReq = true;
// 						instance._localVariables.currentRecordCount = 0;
// 						instance.properties.allAvailableRecords = [];
// 						instance._localVariables.allAvailablePages = {
// 							currLen: 0
// 						};
// 						instance._localVariables.allAvailableBatchs = {
// 							currLen: 0,
// 							batchCount: 0
// 						};
// 						instance._localVariables.latestBatch = 0;
// 						instance.properties.displayRecords = [];
// 						instance.properties.pageNo = 1;
// 						instance.properties.currentLoadingStage = "search"; //NO I18N
// 						if (instance.callBacks.loadingStateUpdateFunc) {
// 							instance.callBacks.loadingStateUpdateFunc(instance, instance.properties.currentLoadingStage);
// 						}
// 						if (instance.callBacks.beforeSearchRequestFunc) {
// 							instance.callBacks.beforeSearchRequestFunc(instance, "beforSearch"); //NO I18N
// 						}
// 						var prom = this.constructNextBatch(instance, false, "search"); //NO I18N
// 						instance._localVariables.oldSearchValue = instance._localVariables.currentSearchValue;
// 						return prom;
// 					} else if (instance.callBacks.beforeSearchRequestFunc) {
// 						instance.callBacks.beforeSearchRequestFunc(instance, "rejected"); //NO I18N
// 					}
// 				}
// 				if (inputVal.length === 0) {
// 					if (!instance._localVariables.multipleBack) {
// 						instance.properties.allRecordsFetched = false;
// 						instance._localVariables.multipleBack = true;
// 						instance.properties.isSearchReq = false;
// 						instance._localVariables.currentRecordCount = 0;
// 						instance.properties.allAvailableRecords = [];
// 						instance._localVariables.allAvailablePages = {
// 							currLen: 0
// 						};
// 						instance._localVariables.allAvailableBatchs = {
// 							currLen: 0,
// 							batchCount: 0
// 						};
// 						instance._localVariables.latestBatch = 0;
// 						instance.properties.displayRecords = [];
// 						instance.properties.pageNo = 1;
// 						instance._localVariables.emptySearchResp = false;
// 						instance.properties.currentLoadingStage = "initial"; //NO I18N
// 						if (instance.callBacks.loadingStateUpdateFunc) {
// 							instance.callBacks.loadingStateUpdateFunc(instance, instance.properties.currentLoadingStage);
// 						}
// 						if (instance.callBacks.beforeSearchRequestFunc) {
// 							instance.callBacks.beforeSearchRequestFunc(instance, "clearSearch"); //NO I18N
// 						}
// 						var prom = this.constructNextBatch(instance, false, "initial"); //NO I18N
// 						instance._localVariables.oldSearchValue = "";
// 						return prom;
// 					}
// 				}

// 			}.bind(this), 300);
// 		},

// 		resetSearch: function (instance) {
// 			instance.properties.allRecordsFetched = false;
// 			instance._localVariables.multipleBack = true;
// 			instance.properties.isSearchReq = false;
// 			instance._localVariables.currentRecordCount = 0;
// 			instance.properties.allAvailableRecords = [];
// 			instance._localVariables.allAvailablePages = {
// 				currLen: 0
// 			};
// 			instance._localVariables.allAvailableBatchs = {
// 				currLen: 0,
// 				batchCount: 0
// 			};
// 			instance._localVariables.latestBatch = 0;
// 			instance.properties.displayRecords = [];
// 			instance.properties.pageNo = 1;
// 			instance.properties.currentLoadingStage = "initial"; //NO I18N
// 			instance._localVariables.emptySearchResp = false;
// 			if (instance.callBacks.loadingStateUpdateFunc) {
// 				instance.callBacks.loadingStateUpdateFunc(instance, instance.properties.currentLoadingStage);
// 			}
// 			if (instance.callBacks.beforeSearchRequestFunc) {
// 				instance.callBacks.beforeSearchRequestFunc(instance, "clearSearch"); //NO I18N
// 			}
// 			var prom = this.constructNextBatch(instance, false, "initial"); //NO I18N
// 			instance._localVariables.oldSearchValue = "";
// 			return prom;
// 		}
// 	}
// });
