Lyte.Component.register("crux-video", {
_template:"<template tag-name=\"crux-video\"> <template is=\"if\" value=\"{{isNotSafari11}}\"><template case=\"true\"> <video src=\"{{src}}\" class=\"{{class}}\" width=\"{{width}}\" height=\"{{height}}\" autoplay=\"{{autoplay}}\" controls=\"{{controls}}\" muted=\"{{muted}}\" onended=\"{{action('ended')}}\" id=\"{{cxPropId}}\"> </video> </template></template> </template>",
_dynamicNodes : [{"type":"attr","position":[1]},{"type":"if","position":[1],"cases":{"true":{"dynamicNodes":[{"type":"attr","position":[1]}]}},"default":{}}],
_observedAttributes :["src","width","height","autoplay","controls","muted","isNotSafari11","class","cxPropId"],//No I18n
	data : function(){
		return {
			src:Lyte.attr("string"),//No I18n
			width:Lyte.attr("string"),//No I18n
			height:Lyte.attr("string"),//No I18n
			autoplay:Lyte.attr("boolean", {default:false}),//No I18n
			controls:Lyte.attr("boolean", {default:false}),//No I18n
			muted:Lyte.attr("boolean",{default: false}),//No I18n
			isNotSafari11:Lyte.attr("boolean",{default :false}),//No I18n
			class:Lyte.attr("string"),//No I18n
			cxPropId : Lyte.attr("string")//No I18n
		}		
	},
	init : function(){
		var isSafari = navigator.userAgent.indexOf("Safari");//No I18n
		if(isSafari != -1)
		{
			var versionArr = navigator.userAgent.match(/version\/(\d+)/i) || [];
			if(!versionArr[1])
			{
				this.setData("isNotSafari11", true);//No I18n
			}
		}	
		else{
			this.setData("isNotSafari11", true);//No I18n
		}
	},
	didConnect : function(){
		if(!this.getData("isNotSafari11")){	
			var video = document.createElement("video")
			video.setAttribute("src", this.getData("src"));
			video.setAttribute("autoplay", this.getData("autoplay"));
			video.setAttribute("controls",this.getData("controls"));
			video.setAttribute("muted", this.getData("muted"));
			video.setAttribute("width", this.getData("width"));
			video.setAttribute("height", this.getData("height"));
			video.addEventListener("ended",function(){
				if(this.parentNode.component.getMethods("onVideoEnded")){
	                this.parentNode.component.executeMethod("onVideoEnded");//No I18n					
				}
            });
            video.setAttribute("id", this.getData("cxPropId"));
            if(this.getData("controls") == false){
            	video.removeAttribute("controls");
            }
			this.$node.appendChild(video);
		}
		this.$node.play = function(){
			this.querySelector("video").play();//No I18n
		}
		this.$node.pause = function(){
			this.querySelector("video").pause();//No I18n
		}
		this.$node.load = function(){
			this.querySelector("video").load();//No I18n
		}
	},
	actions : {
		ended:function(){
			if(this.getMethods("onVideoEnded")){
				this.executeMethod("onVideoEnded");//No I18n				
			}
		}
	}
});
