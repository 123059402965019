if(!window._cruxUtils){
	window._cruxUtils = {};
}
window._cruxUtils.showCustomAlert = function(opt){
	var alert = $L('#cruxCustomAlert'); //no i18n
	if(opt.params && opt.params.ltPropWrapperClass){
		opt.params.ltPropWrapperClass = opt.params.ltPropWrapperClass+" "+'cxAlertWrapper';
	}else{
		opt.params.ltPropWrapperClass = 'cxAlertWrapper' //no i18n
	}
	opt.params = Object.assign({ltPropCloseOnEscape : true,ltPropSlideDown : "slideDown",ltPropContentAlign : "left",ltPropType : "", ltPropPrimaryMessage : "", ltPropSecondaryMessage : "",ltPropAllowMultiple : false,ltPropHeading : "",ltPropButtonPosition : 'center',ltPropTop : '0',ltPropShowCloseButton : false,ltPropButtons : [{"type":"accept","text":_cruxUtils.getI18n('crm.mb.newversion.msg4'),"appearance":"primary"}] },opt.params);//no i18n
	var len = opt.params.ltPropButtons.length;
	for(var i=0;i<len;i++){
		if(opt.params.ltPropButtons[i].type == 'accept' && opt.params.ltPropButtons[i].appearance == undefined){
			opt.params.ltPropButtons[i].appearance = 'primary';
		}
	}
	if(alert.length > 0){
		if(alert[0].ltProp('show')){
			return;
		}
		alert[0].setData(opt.params);
	}else{
		opt.params.id = 'cruxCustomAlert';
		alert.push(Lyte.Component.render('lyte-alert',opt.params,'body'));
	}
	alert[0].setMethods({
		onShow : function(){
			this.$node.component.childComp.querySelectorAll('.alertFooter lyte-button').forEach(function(item,index){ //no i18n
				if(opt.params.ltPropButtons[index] && opt.params.ltPropButtons[index].cxPropZcqa){
					item.setAttribute('data-zcqa',opt.params.ltPropButtons[index].cxPropZcqa)
				}
			})
			if(opt.show){
				opt.show(arguments[0]);
			}
		}, 
		onAccept  : function (){ 
			if(opt.accept){
				opt.accept(arguments[0]); 
			}
		},
		onReject  : function (){ 
			if(opt.reject){
				opt.reject(arguments[0])
			}
	 	},
	 	onClose  : function (){ 
	 		var closeFn = opt.close;
	 		opt={};
			if(closeFn){
				closeFn(arguments[0], arguments[1]);
			}
	 	}
	});
	alert[0].ltProp('show',true);
}

window._cruxUtils.removeCustomAlert = function(){
	if($L('#cruxCustomAlert').length > 0){
		$L('#cruxCustomAlert')[0].remove(); //no i18n
	}
}

window._cruxUtils.showCustomMessage = function(opt){
	if(opt.params && opt.params.ltPropClass){
		opt.params.ltPropClass = opt.params.ltPropClass+" "+'cruxAlertWrapper';
	}else{
		opt.params.ltPropClass = 'cxAlertWrapper' //no i18n
	}
	opt.params.id = 'cruxCustomMessage';
	var message = document.getElementById("cruxCustomMessage");
	message && message.remove();
	if(opt.params.ltPropYield){
		message = document.createElement('lyte-messagebox');
		Object.keys(opt.params).forEach(function(item){
			if(item != 'ltPropMessage'){
				var attr = item.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase()
				message.setAttribute(attr,opt.params[item]);
			}
		}.bind(this))
		message.innerHTML = '<template is = "registerYield" yield-name = "messageboxYield">'+ opt.params.ltPropMessage+'</template>' //no i18n
		Lyte.Component.appendChild($L('body')[0],message);
	}else{
		if(document.getElementById("cruxCustomMessage")){
			document.getElementById("cruxCustomMessage").ltProp("show", false);//No I18n
		}
		message = Lyte.Component.render('lyte-messagebox',opt.params,'body')
	}
	message.setMethods({ 
		onClose  : function (){ 
			if(opt.close){
				opt.close()
			}
			arguments[0].$node.remove();
	 	} 
	});
	message.ltProp('show',true); //no i18n
}
window._cruxUtils.showProfilePermissionModal =  function(opt){
	if(!opt){
		return
	}
	opt.params = opt.params ? opt.params :{} 
	var modalNode = $L('#cruxPermissionPopup')[0]; //no i18n
	if(modalNode){
		opt.params.cxPropSelectedIds = opt.params.cxPropSelectedIds ? opt.params.cxPropSelectedIds :[];
		modalNode.setData(opt.params);
	}else{
		opt.params.id = 'cruxPermissionPopup';
		modalNode=Lyte.Component.render('crux-profile-permission-modal',opt.params,"body");
	}
	modalNode.setMethods({
		onShow : function(){
			if(opt.onShow){
				opt.onShow(arguments[0]);
			}
		},onAddProfile : function (){ 
			if(opt.onAddProfile){
				opt.onAddProfile(arguments[0]); 
			}
		},onBeforeRemoveProfile : function (){ 
			if(opt.onBeforeRemoveProfile){
				opt.onBeforeRemoveProfile(arguments[0]); 
			}
		},onRemoveProfile :  function (){ 
			if(opt.onRemoveProfile){
				return opt.onRemoveProfile(arguments[0]); 
			}
		},onClose  : function (){ 
			if(opt.onClose){
				opt.onClose(arguments[0]);
			}
	 	},onBeforeSave  : function (){ 
			if(opt.onBeforeSave){
				return opt.onBeforeSave(arguments[0]);
			}
	 	}
	})
	modalNode.setData("cxPropShow",true)//no i18n
	
}

window._cruxUtils.showSchedularNotification = function( opt ){
	// opt = {params : { cxPropHeader : "lead status" , cxPropMessage : "Lead conversion is progress ...." , cxPropStatus : "progress" } }
	// opt.params.cxPropLink = [{route : "crm.settings.index" , message : "settings" , class : "testClass"}]
	if(!opt){
		return
	}
	opt.params = opt.params ? opt.params :{} 
	var schedularNode = $L('#cruxSchedularPopup')[0]; //no i18n
	if(schedularNode){
		schedularNode.setData(opt.params);
	}else{
		opt.params.id = 'cruxSchedularPopup';
		schedularNode=Lyte.Component.render('crux-schedular-notification-popup',opt.params,"body");
	}
	schedularNode.setMethods({
		onShow : function(){
			
			if(opt.onShow){
				opt.onShow(arguments[0]);
			}
		},onClose  : function (){ 
			if(opt.onClose){
				opt.onClose(arguments[0]);
			}
	 	}
	})
	schedularNode.setData("cxPropShow",true)//no i18n
}

window._cruxUtils.showLookupModal = function(op){
	var ele = document.querySelector("crux-lookup-modal");
	if(!ele){
		ele = Lyte.Component.render("crux-lookup-modal", {}, op.body ? op.body : "body");		
	}
	if(!op.data){
		op.data = {};
	}
	var toSet = ["cxPropFields", "cxPropMetaMoreRecords", "fieldMapping", "cxPropValue", "cxPropShowNavigator"];
	var data = {};
	toSet.forEach(function(key){
		data[key] = op.data[key];
	})
	ele.setData(data);
	if(!op.methods){
		op.methods = {};
	}
	ele.setMethods({
		fetchModuleData : op.methods.fetchModuleData,
		fetchRecords : op.methods.fetchRecords,
		onValueChange : op.methods.onValueChange
	})
	ele.component.lookupInit(ele.component,op.data.cxPropField.lookup.module.id,true);
	/*
	isSingle : true, renderLookup : true, dispInit : true
	if(this.getMethods("onBeforeShowLookup")){
			var res = this.executeMethod("onBeforeShowLookup");
			if(res == false){
				return false;
			}
		}
	    if(typeof commonUtils != "undefined" && commonUtils.showHideLoadingDiv){
			commonUtils.showHideLoadingDiv(true);
		}
		*/
}
